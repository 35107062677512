<template>
  <section class="section has-background-white">
    <div class="container">
      <section-header
        :title="`Groups (${hasGroups ? groups.length : 0})`"
        title-icon="fal fa-object-group"
      />
      <div class="field is-scroll">
        <table
          class="table is-fullwidth"
          :class="[hasGroups ? 'is-striped is-hoverable' : '']"
        >
          <table-header
            @toggle="toggleSelect"
            v-bind="{ columns, sort }"
            @change="setSort"
            v-if="hasGroups"
          />
          <tbody>
            <tr v-if="!hasSearched && !$wait.is('search')">
              <td colspan="8">
                <table-empty text="You haven't searched yet" />
              </td>
            </tr>
            <tr v-else-if="$wait.is('search')">
              <td v-for="index in columns.length" :key="index">
                <content-placeholder />
              </td>
            </tr>
            <tr v-else-if="isEmpty">
              <td colspan="8">
                <table-empty text="No results found for this make/model" />
              </td>
            </tr>
            <tr
              v-else-if="hasGroups"
              v-for="group in pageGroups"
              :key="group.originalIndex"
            >
              <td class="filter-menu-dropdown">
                <span class="radio-tick">
                  <checkbox :value="group.originalIndex" v-model="request" />
                </span>
              </td>
              <td>
                <span>{{ group.make }}</span>
              </td>
              <td>
                <span>{{ group.model }}</span>
              </td>
              <td>
                <span>{{ group.count }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination v-bind="{ page, pages }" @change="setPage" v-if="pages" />
      </div>
    </div>
  </section>
</template>

<script>
import tableHeader from 'core/modules/table-header/mixins/tableHeader.mixin'
import pagination from 'core/modules/pagination/mixins/pagination.mixin'
import { mapGetters, mapState, mapMutations } from 'vuex'
export default {
  name: 'ExplorerGroupTable',
  mixins: [
    tableHeader({ fetch: 'fetch' }),
    pagination({
      fn: {
        data: 'pages'
      }
    })
  ],
  components: {
    sectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    contentPlaceholder: () => import('core/components/ContentPlaceholder'),
    tableEmpty: () => import('core/components/TableEmpty'),
    tableHeader: () =>
      import('core/modules/table-header/components/TableHeader')
  },
  computed: {
    ...mapState('explorer', ['groups']),
    ...mapGetters('explorer', ['hasGroups', 'hasSearched']),
    request: {
      get() {
        return this.$store.getters['explorer/request']
      },
      set(request) {
        this.setRequest(request)
      }
    },
    columns() {
      return [
        { label: 'Select', select: true },
        { label: 'Make' },
        { label: 'Model' },
        { label: 'Adverts' }
      ]
    },
    isEmpty() {
      return this.hasSearched && !this.hasGroups
    },
    perPage() {
      return 10
    },
    pages() {
      if (!this.groups) return 0

      return Math.ceil(this.groups.length / this.perPage)
    },
    pageGroups() {
      const indexedGroups = this.groups.map((group, index) => ({
        ...group,
        originalIndex: index
      }))
      const start = (this.page - 1) * this.perPage
      const end = start + this.perPage
      return indexedGroups.slice(start, end)
    }
  },
  methods: {
    ...mapMutations('explorer', ['selectAll', 'deselectAll', 'setRequest'])
  }
}
</script>
